import React, { createContext, useContext, useState, useEffect } from "react";
import { firestore } from "../firebaseconfig";
import { Timestamp, getDoc, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getResaDay, isBetween } from "../utils/utils";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import getAvailableTimeSlots, {
  timeStringToMinutes,
  getDayOfWeek,
} from "../horairesSelectable";

// Créez un contexte pour les réservations
export const ReservationContext = createContext(null);
// Utilisez un Provider pour envelopper la partie de votre application qui a besoin d'accès aux réservations
export const ReservationProvider = ({ children }) => {
  const [resetStates, setResetStates] = useState(false);
  const [tables, setTables] = useState([]);
  const [maxCustomers, setMaxCustomers] = useState(2);
  const [calendardate, setCalendardate] = useState(new Date()); // Ici, vous pouvez mettre la date actuelle ou toute autre date par défaut
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedHoraire, setSelectedHoraire] = useState("");
  const [selectedTableIds, setSelectedTableIds] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null); // Pour stocker l'ID de la table sélectionnée
  const [selectedService, setSelectedService] = useState(null); // Pour stocker le service de la table sélectionnée
  const [table, setTable] = useState(null); // Pour stocker la table sélectionnée
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customersNumber, setCustomersNumber] = useState(2);
  const [notes, setNotes] = useState("");
  const [restauSelected, setRestauSelected] = useState(null);
  const [allReservations, setallReservations] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [QuantitySelected, setQuantitySelection] = useState(false);
  const [HoraireSelected, setHoraireSelection] = useState(false);

  const handleCustomerNameChanges = (name) => {
    setCustomerName(name);
  };

  const getCustomersNumber = () => {
    return customersNumber;
  };

  const resetWhenRestauChange = () => {
    setResetStates(true);
  };

  useEffect(() => {
    console.log("resettedState", resetStates);
    if (resetStates === true) {
      setallReservations([]);
      setCustomersNumber(null);
      setMaxCustomers(1);
      setTables([]);
      setTable(null);
      setTimeSlots([]);
      setSelectedHoraire("");
      setSelectedTableIds([]);
      setSelectedTable(null);
      setSelectedService(null);
      setResetStates(false);
    }
  }, [resetStates]);

  useEffect(() => {
    console.log("customerName setted:", customerName);
  }, [customerName]);

  const canClickReservation = (iscliquable) => {
    return !(
      !phoneNumber.trim() ||
      !selectedService ||
      !selectedTable ||
      !table ||
      customersNumber === undefined ||
      customerName === "" ||
      !QuantitySelected ||
      !HoraireSelected
    );
  };
  // Fonction pour valider une réservation
  const validateReservation = async (onSuccess, onError) => {
    if (!canClickReservation()) {
      alert(
        "Les champs obligatoires sont le numéro de téléphone, la selection d'une table, la selection d'un nom et la selection d'un horaire."
      );
      onError();
      return;
    }
    // Logique de validation...
    const idResa = uuidv4();

    const timeStart = createFirestoreTimestamp(selectedHoraire, calendardate);
    const dataToSend = {
      id: idResa,
      idClient: "webappID",
      idService: selectedService.id,
      idRestau: restauSelected,
      customersCount: getCustomersNumber(),
      nameClient: customerName,
      adresse: getAddressFromSelection(),
      note: notes,
      phoneNumber: phoneNumber,
      duration: selectedService.numberMinutesResa,
      nameTable: table.name,
      tableId: selectedTable,
      timeStart: timeStart,
      state: 1,
    };

    try {
      // Envoyer les données à Firestore dans la collection "cities"
      const docRef = doc(firestore, "Reservations", idResa);

      const docSetted = await setDoc(docRef, dataToSend);
      console.log("Document écrit avec l'ID: ", docRef.id);
      const timeStartDate = timeStart
        ? new Date(timeStart.seconds * 1000)
        : new Date();

      onSuccess(timeStartDate, customersNumber, phoneNumber, customerName);
    } catch (e) {
      console.error("Erreur lors de l'ajout du document: ", e);
      onError();
    }
  };

  const handleClickRestau = (enseigne) => {
    let restauid; // Déclare restauid une seule fois ici

    switch (enseigne) {
      case "Melun":
        restauid = "I2tSGJEdBZQRxT6ZXf0r51eFbfl1";
        // restauid = "R0Z8fKPhFMQZeEj4H5Ij5sdSGlG2";
        break;
      case "Bois-Colombes":
        restauid = "L5vHL7z7dcdPVAiiXtMJGIjlLlF2";
        // brother place restauid = "MydKHgrbSmRuAv3fvlUV5xv6sCL2";
        break;
      case "Pavillon-sous-bois":
        restauid = "rQVzDhf27NfK62UE2VB6UYMkXZ43";
        break;
      case "Les Clayes-sous-bois":
        restauid = "OO7cdEYfC3gybADPrcHSkiJyTrv2";
        break;
      case "Arcueil":
        restauid = "a8UkOXK5VffDK0lvo5kzRsiWdXc2";
        break;
      case "Asnières-sur-Seine":
        restauid = "P4dVdEAfmddEnj0SMLdxoG2xCn32";
        break;
      case "Saint Ouen":
        restauid = "1QsKMWSjqcbOxFDvcaT7VqWa2393";
        break;
      case "Paris Opéra":
        restauid = "tZav8u3foIR7lRGi2M8RM1iR65b2";
        break;
      case "Argenteuil":
        restauid = "KJ2IJJOKTEMn4KJ8UCQUbn8oyBo2";
        break;
      default:
        alert("Restau inconnu");
    }
    setRestauSelected(restauid);
  };

  const getAddressFromSelection = () => {
    let address = "";
    switch (restauSelected) {
      case "I2tSGJEdBZQRxT6ZXf0r51eFbfl1":
        address = "20 Rue Sainte-Ambroise, 77000 Melun";
        break;
      case "L5vHL7z7dcdPVAiiXtMJGIjlLlF2":
        address = "203-207 Avenue d'Argenteuil, 92270 Bois-Colombes";
        break;
      case "rQVzDhf27NfK62UE2VB6UYMkXZ43":
        address = "50B Av. Aristride Briands, 93320 Les Pavillons-sous-Bois";
        break;
      case "a8UkOXK5VffDK0lvo5kzRsiWdXc2":
        address = "2 Rue Guy de Gouyon-du-Verger, 94140 Arcueil";
        break;
      case "OO7cdEYfC3gybADPrcHSkiJyTrv2":
        address = "90 Av. Henri Barbusse, 78340 Les Clayes-sous-Bois";
        break;
      case "P4dVdEAfmddEnj0SMLdxoG2xCn32":
        address = "4 Avenue Laurent Cely, Asnières-sur-Seine";
        break;
      case "1QsKMWSjqcbOxFDvcaT7VqWa2393":
        address = "10 bis Rue de l Hippodrome, Saint-Ouen-sur-Seine";
        break;
      case "tZav8u3foIR7lRGi2M8RM1iR65b2":
        address = "9 rue Danielle Casanova, 75001 Paris";
        break;
      case "KJ2IJJOKTEMn4KJ8UCQUbn8oyBo2":
        address = "75 Avenue Gabriel Péri, 95100 Argentueil";
        break;
      default:
        address = "Adresse inconnue";
    }
    return address;
  };

  const handleSelectTable = (tableId) => {
    setSelectedTable(tableId);

    // Récupération de la table réservée
    const reservedTable = tables.find((table) => tableId === table.id);

    setTable(reservedTable);
    if (!reservedTable) {
      console.error("Aucune table trouvée avec cet ID");
      return;
    }

    const dayOfWeek = getDayOfWeek(calendardate).toLowerCase();
    console.log("dayofweek:", dayOfWeek);

    const services = reservedTable[dayOfWeek]?.services;

    console.log("services:", services);
    const service = findServiceForDateTime(
      timeStringToMinutes(selectedHoraire),
      services
    );
    console.log("service:", service);

    setSelectedService(service);
  };

  useEffect(() => {
    console.log("phoneNumber setted:", phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    console.log("table setted:", table);
  }, [table]);

  useEffect(() => {
    console.log("selectedService setted:", selectedService);
  }, [selectedService]);

  useEffect(() => {
    console.log("set selected restau", restauSelected);
    if (restauSelected !== null) {
      resetWhenRestauChange();

      const idrestau = restauSelected;
      console.log("idrestau getalltable :", idrestau);
      getAllTables(idrestau)
        .then(() => {
          console.log("Tables fetched successfully");
        })
        .catch((error) => {
          console.error("Failed to fetch tables:", error);
        });
      getAllReservations(calendardate, idrestau)
        .then(() => {
          console.log(
            "getAllReservations fetched successfully for",
            calendardate,
            "end",
            idrestau
          );
        })
        .catch((error) => {
          console.error("getAllReservations to fetch tables:", error);
        });
    }
  }, [restauSelected]);

  const findServiceForDateTime = (dateTime, services) => {
    // Parcourir chaque service pour trouver une correspondance
    for (let service of services) {
      const start = service.dateStartService.toDate();
      const end = service.dateEndService.toDate();

      // Convertir les heures de début et de fin en minutes
      const startInMinutes = start.getHours() * 60 + start.getMinutes();
      const endInMinutes = end.getHours() * 60 + end.getMinutes();

      // Vérifier si l'heure est dans l'intervalle du service
      if (dateTime >= startInMinutes && dateTime <= endInMinutes) {
        return service; // Service correspondant trouvé
      }
    }
    // Aucun service correspondant trouvé
    return null;
  };

  const createFirestoreTimestamp = (selectedSlot, selectedDate) => {
    if (!selectedSlot) {
      console.log("ERROR_OCCURED__:", selectedSlot, "retuned :", Date());
      const newtimestamp = Timestamp.fromDate(new Date());
      return newtimestamp;
    }
    const timeParts = selectedSlot.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    // Créez une nouvelle date qui combine la date sélectionnée et l'heure
    const combinedDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    );

    // Convertissez l'objet Date combiné en Timestamp Firestore
    const firestoreTimestamp = Timestamp.fromDate(combinedDate);

    return firestoreTimestamp;
  };

  const handleSelectDate = (date) => {
    setCalendardate(date);
  };

  useEffect(() => {
    if (tables.length > 0) {
      // Cette fonction sera appelée à chaque fois que 'calendarDate' est mise à jour
      if (calendardate) {
        console.log(
          "calendardate et allReservations au moment de l'appel:",
          calendardate,
          allReservations
        );

        getAllReservations(calendardate, restauSelected)
          .then(() => {
            console.log(
              "getAllReservations fetched successfully for",
              calendardate,
              "end",
              restauSelected
            );
          })
          .catch((error) => {
            console.error("getAllReservations to fetch tables:", error);
          });
        console.log("État des tables au moment de l'appel:", tables);
        console.log("État de calendardate au moment de l'appel:", calendardate);
        const newTimeSlots = getAvailableTimeSlots(tables, calendardate);
        setTimeSlots(newTimeSlots); // Mettre à jour les créneaux horaires disponibles
        console.log("timeslots:", timeSlots, newTimeSlots);
      }
    }
  }, [calendardate, tables]);

  useEffect(() => {
    if (tables.length > 0) {
      const maxCust = findMaxNumberMaxPersonnes(tables);
      setMaxCustomers(maxCust);
      console.log("MAXCUSTOMERS:", maxCust);
      console.log("MAXCUSTOMERS:", maxCustomers);
    }
  }, [tables]); // Se déclenche chaque fois que `tables` change

  const handleSelectCustomers = (customersSelected) => {
    if (customersSelected !== undefined && customersSelected !== null) {
      console.log("customers setted", customersSelected);
      setCustomersNumber(customersSelected);
    }
  };

  useEffect(() => {
    console.log("customersNumber:", customersNumber);
  }, [customersNumber]);

  useEffect(() => {
    console.log(
      "HoraireSelected et QuantitySelected:",
      QuantitySelected,
      HoraireSelected
    );
  }, [HoraireSelected, QuantitySelected]);

  const handleSelectSlot = (selection) => {
    console.log("handleSelectSlot:e", selection, selection);

    if (!selection) {
      console.log("handleSelectSlot:ERROR____", selection);
      return;
    }
    console.log("handleSelectSlot°____:", selection);

    setSelectedHoraire(selection.time); // update le state pour le recup tarplu --> 2 jours à chercher pck selection s'appelait selectedSlot donc j'ai cru que ct déjà updaté !
    console.log("handleSelectSlot:", selection, "for", selectedHoraire);

    // Filtrer pour trouver les tableIds où l'heure sélectionnée est incluse dans les créneaux horaires
    console.log("tables__=", selection.tables, selection);
    setSelectedTableIds(selection.tables);
  };

  useEffect(() => {
    console.log("useEffect handleSelection horaire", selectedHoraire);
  }, [selectedHoraire]);

  useEffect(() => {
    console.log("tableIDs après mise à jour:", selectedTableIds);
  }, [selectedTableIds]); //

  useEffect(() => {
    console.log("table après mise à jour:", selectedTable);
  }, [selectedTable]); //

  useEffect(() => {
    console.log("allReservations_LAUNCHED:", allReservations);
  }, [allReservations]);

  const getAllTables = async (idRestau) => {
    try {
      const tablesref = query(
        collection(firestore, "TablesReservations"),
        where("idRestau", "==", idRestau)
      );
      const fetchedTables = await getDocs(tablesref);

      const tablesArray = fetchedTables.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("MAXCUSTOMERS:", maxCustomers);

      // Set the tables state with the array of documents
      setTables(tablesArray);
      //setmaxcustomers();

      console.log("Tables:", tablesArray);
    } catch (error) {
      console.error("error is: ", error);
    }
  };

  const getAllReservations = async (day, idRestau) => {
    // create from day : day 1 = lundi 00:00 et day 2 lundi 23:59:59
    try {
      const startday = new Date(day); // Copie de la date de début
      const endday = new Date(day); // Copie pour créer la date de fin
      endday.setHours(23, 59, 59);

      const reservationRef = query(
        collection(firestore, "Reservations"),
        where("idRestau", "==", idRestau),
        where("state", "not-in", [2, 4, 5]),
        where("timeStart", ">=", startday),
        where("timeStart", "<=", endday)
      );
      const fetchedReservations = await getDocs(reservationRef);
      console.log(
        "for ",
        idRestau,
        "and",
        day,
        "fetchedReservations__:",
        fetchedReservations.length
      );
      // Mapping sur les documents pour extraire les données
      const reservationData = fetchedReservations.docs.map((doc) => {
        // Récupérer les données originales
        const data = doc.data();
        // Récupérer timeStart qui est un objet Timestamp de Firestore
        const timeStart = data.timeStart;

        // recup l'id de la table
        const tableID = data.tableId;

        // Convertir duration en millisecondes (1 minute = 60000 millisecondes)
        const durationMs = data.duration * 60000;

        // Créer une nouvelle Date pour timeEnd en ajoutant la durée à timeStart
        const timeEnd = new Date(timeStart.seconds * 1000 + durationMs);
        console.log("résas fetched data:", data);

        // Convertir les objets Date en objets Timestamp de Firestore pour timeEnd
        const timeEndTimestamp = Timestamp.fromDate(timeEnd);

        console.log("timeEndTimestamp timeEndTimestamp:", timeEndTimestamp);

        // Retourner le nouvel objet avec timeStart et timeEnd en format Timestamp
        return {
          timeStart,
          timeEnd: timeEndTimestamp,
          tableID,
        };
      });
      console.log("RÉSAS FETECHED_END__:", reservationData);
      setallReservations(reservationData);
    } catch (error) {
      console.error("RÉSAS FETECHED__ERROR__:", error);
    }
  };

  const computeServicesOfDay = (
    setServicesOfDay,
    setTablesOfDay,
    tables,
    dayChosen
  ) => {
    const day = dayChosen
      .toLocaleString("en-US", { weekday: "long" })
      .toUpperCase(); // ou une autre locale selon vos besoins
    const servicesTable = [];

    tables.forEach((table) => {
      const services = table[day.toLowerCase()].services; // Assurez-vous que le nom des jours est correct et existe dans l'objet table
      if (services && services.length > 0) {
        servicesTable.push(...services);
        setTablesOfDay((tablesOfDay) => [...tablesOfDay, table]);
      }
    });

    setServicesOfDay(servicesTable);
  };
  // ...autres fonctions
  // Valeurs et fonctions à fournir à laisser en fin de déclaration car il faut déclarer au dessus
  const value = {
    resetStates,
    HoraireSelected,
    QuantitySelected,
    setQuantitySelection,
    setHoraireSelection,
    setResetStates,
    canClickReservation,
    getAddressFromSelection,
    tables,
    customerName,
    setCustomerName,
    setTables,
    maxCustomers,
    setMaxCustomers,
    getAllTables,
    getAllReservations,
    allReservations,
    setSelectedService,
    selectedService,
    calendardate,
    setCalendardate,
    timeSlots,
    setTimeSlots,
    setSelectedHoraire,
    selectedHoraire,
    handleSelectSlot,
    setSelectedTableIds,
    selectedTableIds,
    setSelectedTable,
    selectedTable,
    handleSelectTable,
    validateReservation,
    handleSelectCustomers,
    phoneNumber,
    setPhoneNumber,
    table,
    notes,
    setNotes,
    handleSelectDate,
    handleCustomerNameChanges,
    restauSelected,
    setRestauSelected,
    getCustomersNumber,
    handleClickRestau,
    // ...autres valeurs et fonctions
  };
  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  );
};

function findMaxNumberMaxPersonnes(tables) {
  // Démarrez avec une valeur initiale de 0 pour le nombre max de personnes.
  let maxNumberMaxPersonnes = 1;

  tables.forEach((table) => {
    // Parcourez chaque jour de la semaine.
    [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ].forEach((day) => {
      // Vérifiez si le jour actuel existe et a un tableau de services.
      if (table[day] && Array.isArray(table[day].services)) {
        // Trouvez le nombre max de personnes pour chaque service de ce jour spécifique.
        const dayMax = table[day].services.reduce((max, service) => {
          return service.numberMaxPersonnes > max
            ? service.numberMaxPersonnes
            : max;
        }, 0);

        // Mettez à jour le nombre max global si le nombre max du jour actuel est plus grand.
        if (dayMax > maxNumberMaxPersonnes) {
          maxNumberMaxPersonnes = dayMax;
        }
      }
    });
  });
  console.log("maxNumberMaxPersonnes__", maxNumberMaxPersonnes);
  return maxNumberMaxPersonnes;
}

export default ReservationContext;
