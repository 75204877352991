import React, { useContext, useState } from 'react';
import './RestauList.css'; // N'oublie pas de créer le fichier CSS correspondant
import './RestauCard.css';
import placeholderIcon from './logo.svg'; // Icône de placeholder, remplace-la par l'importation de tes icônes
import ReservationContext from './webService/Reservations';
import logo from './logo.svg';
import { ActionType } from './ActionType'; // Importation de l'enum

const RestauList = ({ actions }) => {
  const [selectedId, setSelectedId] = useState(null); // Add state to track the selected action

  const handleClick = (id, onClick) => {
    setSelectedId(id); // Update the selected ID state
    onClick(); // Execute the original onClick function
  };

  return(
    <div className="action-list">
    {actions.map((action, index) => (
      <div key={action.id} className={`action-item ${index === 0 && actions.length % 2 !== 0 ? 'full-width' : ''}`}>
        <RestauCard icon={action.icon} title={action.title} isSelected={selectedId === action.id}  onClick={() => handleClick(action.id, action.onClick)} />
      </div>
    ))}
  </div>
  );
};

const RestauCard = ({ icon = placeholderIcon, title, isSelected, onClick }) => {

  return(
      <div className={`action-card ${isSelected ? 'selected' : ''}`} onClick={onClick} role="button" tabIndex={0}>
          <p className="action-card-title">{title}</p>
      </div>
  );
};

export default RestauList;
